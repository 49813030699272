import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '../../services/authenticate-service/authorization.service';
import { LocalStorageService } from 'angular-2-local-storage';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.css']
})
export class ProgramsComponent implements OnInit {

  private urlMappings: any;
  private programList: string[] = [];
  public cad:string="ContractList";
  public cfg:string="ClientConfigReport";
  public cfgNew:string="ClientConfig";
  

  getProgramList() {
    return this.programList;
  }

  getIscontractAdminUser():boolean{
    var res = localStorage.getItem('data.ContractAdminUser');
    if(res == "true") 
      return true;
    else
      return false;
   }

   getIsclientConfigUser():boolean{
    var res = localStorage.getItem('data.ClientConfigUser');
    if(res == "true") 
      return true;
    else
      return false;
   }


   constructor(public authservice: AuthorizationService, public localStorage:LocalStorageService) {
   }

  ngOnInit(): void {
  }

  ngDoCheck(){
    this.urlMappings = this.authservice.getUrlMappings();
    this.urlMappings.forEach((report: any) => {
      let program = report.program;
      this.programList.push(program);
    })
    this.programList = [...new Set(this.programList)].sort();
  }
}
