import * as CryptoJS from 'crypto-js';
import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthorizationInterceptor } from 'src/app/interceptors/authorization.interceptor';
import { HttpResponseCheck } from "src/app/services/httpresponse-check/htttpResponseCheck.service"


@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  public userActive: Subject<any> = new Subject();
  public userInactive: Subject<any> = new Subject();
  private loggedIn = false;
  private isTokenExpired = false;
  private expirationTime: any;
  private refreshTokenValue = '';
  private uuid = '';
  private programInfo = '';
  private programsAbbreviationMap: any = {
    'EOC': 'Episode of Care',
    'HL': 'Health Link',
    'PCMH': 'Patient Centered Medical Home',
    'TENNSTAR': 'TennStar'
  }
  private urlMappings: [];
  private userType = '';
  private states : [];
  private providers : [];
  private eocs:[];
  private mps:any;
  private indicators:[];
  private reporttypes:[];
  private contractStartDate: any;
  private contractEndDate: any;
  private tins:[];
  private tinnames:[];
  private tinNumber= '232106895';
  private email = "";
  private clientconfigs=[];
  private contractdatalist=[];
  private contractdata=[];
  private contractQTdata=[];
  private contractCTdata=[];
  private qmtiers:[];
  private reportCyclesdata=[];


  constructor(
    private storage_token: LocalStorageService,
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private route: Router,
    private httpResCheck: HttpResponseCheck
  ) {
    this.urlMappings = [];
    this.states =[];
    this.providers=[];
    this.eocs =[];
    this.mps = [];
    this.indicators =[];
    this.reporttypes=[];
    this.tins=[];
    this.tinnames=[];
    this.clientconfigs=[];
    this.contractdatalist=[]
    this.qmtiers=[];
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.code) {
        this.getAccessToken(params.code, params.state);
      }
    });
    this.storage_token.set('tinNumber', this.tinNumber);
    this.urlMappings = this.storage_token.get('urlMappings') || [];
  }

  public setExpirationTime(timeValue: number) {
    this.expirationTime = timeValue;
  }

  getClientConfigList()
  {
    //console.log("getClientConfigList()  called", this.clientconfigs.length);
    return this.clientconfigs;
  }

  getcontractdataList()
  {
    return this.contractdatalist;
  }
  

  getTINSList()
  {
    //console.log("Get getTINSList called ", this.tins);
    return this.tins;
  } 

  getTINNamesList()
  {
    //console.log("Get getTINNamesList called ", this.tinnames);
    return this.tinnames;
  } 

  getReportTypeList()
  {
    //console.log("Get getReportTypeList called ", this.reporttypes);
    return this.reporttypes;
  }

  getStateList()
  {
    //console.log("Get States called ", this.states);
    return this.states;
  } 
  
  getProviderList()
  {
    //console.log("Get providers called ", this.providers);
    return this.providers;
  } 

  getEOCList()
  {
    //console.log("Get EOCS called ", this.eocs);
    return this.eocs;
  } 

  getMPList()
  {
    //console.log("getMPList() from auth.service ", this.mps);
    return this.mps;
  } 
 getIndicatorsList()
 {
  //console.log("Get getIndicatorsList", this.indicators);
  return this.indicators;

 }

  getExpirationTime() {
    return this.expirationTime;
  }

  public setProgram(program: string) {
    this.programInfo = program;
  }

  getProgram() {
    return this.programInfo;
  }

  public setUrlMappings(urlMappings: any) {
    this.urlMappings = urlMappings;
  }

  getUrlMappings() {
    return this.urlMappings;
  }

  
  public setUserType(userType: string) {
    this.userType = userType;
  }

  getUserType() {
    return this.userType;
  }

  public setTinNumber(tinNumber: string) {
    this.tinNumber = tinNumber;
  }

  getTinNumber() {
    return this.tinNumber;
  }

  getProgramFullName(program: string) {
    return this.programsAbbreviationMap[program];
  }

  geQMTiersList()
  {
   return this.qmtiers;
  }


  getcontractData()
  {
    return this.contractdata;
  }

  getcontractCTData()
  {
    return this.contractCTdata;
  }

  getcontractQTData()
  {
    return this.contractQTdata;
  }

  private strRandom(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  isUserLoggedIn() {
    if (localStorage.getItem('data.access_token')) {
      return true;
    }
    else {
      return false;
    }
  }

  goToLoginPage() {
    const state = this.strRandom(40);
    const codeVerifier = this.strRandom(128);
    this.storage_token.set('state', state);
    this.storage_token.set('codeVerifier', codeVerifier);
    const codeVerifierHash = CryptoJS.SHA256(codeVerifier).toString(CryptoJS.enc.Base64);
    const codeChallenge = codeVerifierHash
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
    const params = [
      'response_type=code',
      'state=' + state,
      'client_id=' + environment.oauthClientId,
      'scope=openid+profile+email+address+phone',
      'code_challenge=' + codeChallenge,
      'code_challenge_method=S256',
      'redirect_uri=' + encodeURIComponent(environment.oauthCallbackUrl),
    ];
    window.location.href = environment.oauthLoginUrl + '?' + params.join('&');
  }

  setUsername(username: string) {
    this.storage_token.set('username', username);
  }

  callDigitalSecuirtyAPI(uuid: string) {
    this.http.get(environment.digitalSecurityURL, {
      headers: {
        'Authorization': "Bearer " + this.storage_token.get('access_token')
      },
      params: new HttpParams({fromObject: {
        uuid: uuid
      }}),
      observe: 'response',
      responseType: 'json'
    }).subscribe(response => {
      this.httpResCheck.CheckHttpResponseNotify(response);
      Object.entries(response.body || {}).forEach(([key, value]) => {
        // response has MPIN and TINs.
        if (value == 'User Not Found') {
          window.location.href = environment.digitalSecurityRegUrl;
        }
        if (value == 'Vendor') {
          this.route.navigate(['/contact-us']);
        }
        if (value == 'Provider') {
          Object.entries(response.body || {}).forEach(([key, value], index) => {
            if (key == 'program' && value == '') {
              this.route.navigate(['/contact-us']);
            }
            if (key == 'program' && value.$values.length > 0 )
            {
              this.setProgram(value.$values);
              this.storage_token.set('enrolled_programs', this.getProgram());
            }
            if (key == 'userType')
            {
              this.setUserType(value);
              this.storage_token.set('user_type', this.getUserType());
            }
            if (key == 'tinValue')
            {
              //console.log("setTinNumber: ", value);
              this.setTinNumber(value);
              this.storage_token.set('tinNumber', this.tinNumber);
            }
          });
        }
      });
    },(error => {
      this.httpResCheck.NotifyError(error.status);
    }));
  }

  checkUserInternalExternal(email: string) {
    this.storage_token.set('email', email);
    this.http.get(environment.checkUserRegistry,{
      headers: {
        'Authorization': "Bearer " + this.storage_token.get('access_token')
      },
      observe: 'response',
      responseType: 'json'
    }).subscribe((response: any) => {
      this.httpResCheck.CheckHttpResponseNotify(response);
      this.urlMappings = response.body!.mappings.$values;
      this.storage_token.set('urlMappings', this.urlMappings);
      if (response.body!.internalUser || false) {
        //console.log('Internal User');
        //console.log('All reports visible#########################################');
        this.setUserType('Plan');
        this.storage_token.set('user_type', this.getUserType());
      }
      else {
        ////console.log('External User', this.uuid);
        ////console.log(this.uuid);
        this.callDigitalSecuirtyAPI(this.uuid);
      }
    },(error => {
      this.httpResCheck.NotifyError(error.status);
    }));
  }


  
  checkContractAdminUser() {

    if(environment.production!=true)
      {
        this.http.get(environment.checkContractAdminUserRegistry,{
          headers: {
            'Authorization': "Bearer " + this.storage_token.get('access_token')
          },
          observe: 'response',
          responseType: 'json'
        }).subscribe((response: any) => 
          {
             //console.log("checkContractAdminUser() Called")
             this.httpResCheck.CheckHttpResponseNotify(response);
             this.storage_token.set('ContractAdminUser', response.body);
             //console.log("Trying to write from storage_token ContractAdminuser from Response.body's value", response.body);
          });
      }
      }

  checkClientConfigUser() {
    if(environment.production!=true)
      {
    this.http.get(environment.checkClientConfigUserRegistry,{
      headers: {
        'Authorization': "Bearer " + this.storage_token.get('access_token')
      },
      observe: 'response',
      responseType: 'json'
    }).subscribe((response: any) => 
      {
         //console.log("checkClientConfigUser() Called")
         this.httpResCheck.CheckHttpResponseNotify(response);
         this.storage_token.set('ClientConfigUser', response.body);
         //console.log("Trying to write from storage_token checkClientConfigUser from Response.body's value", response.body);
      });
    }
  }

  GetStates() {
    if(environment.production!=true)
      {
    this.http.get(environment.StateRegistry,{
      headers: {
        'Authorization': "Bearer " + this.storage_token.get('access_token')
      },
      observe: 'response',
      responseType: 'json'
    }).subscribe((response: any) => 
      {
         this.httpResCheck.CheckHttpResponseNotify(response);
         this.states = response.body.$values;
         //console.log("GetStates() Called and Data populated", response);
      });
    }
  }

  GetTINS() {

    if(environment.production!=true)
      {
    this.http.get(environment.TINSRegistry,{
      headers: {
        'Authorization': "Bearer " + this.storage_token.get('access_token')
      },
      observe: 'response',
      responseType: 'json'
    }).subscribe((response: any) => 
      {
         this.httpResCheck.CheckHttpResponseNotify(response);
         this.tins = response.body.$values;
         //console.log("GetTINS() Called and Data populated", response);
      });
    }
  }

  GetTINNames() {
    if(environment.production!=true)
      {
    this.http.get(environment.TINNamesRegistry,{
      headers: {
        'Authorization': "Bearer " + this.storage_token.get('access_token')
      },
      observe: 'response',
      responseType: 'json'
    }).subscribe((response: any) => 
      {
         this.httpResCheck.CheckHttpResponseNotify(response);
         this.tinnames = response.body.$values;
         //console.log("GetTINNames() Called and Data populated", response);
      });
    }
  }

  GetProviders() {
    if(environment.production!=true)
      {
    this.http.get(environment.ProviderRegistry,{
      headers: {
        'Authorization': "Bearer " + this.storage_token.get('access_token')
      },
      observe: 'response',
      responseType: 'json'
    }).subscribe((response: any) => 
      {
         this.httpResCheck.CheckHttpResponseNotify(response);
         this.providers = response.body.$values;
         //console.log("GetProviders() Called and Data populated", response);
         //console.log(response.body.$values);
      });
    }
  }

  GetEocs() {
    if(environment.production!=true)
      {
    this.http.get(environment.EOCRegistry,{
      headers: {
        'Authorization': "Bearer " + this.storage_token.get('access_token')
      },
      observe: 'response',
      responseType: 'json'
    }).subscribe((response: any) => 
      {
         this.httpResCheck.CheckHttpResponseNotify(response);
         this.eocs = response.body.$values;
         //console.log("GetEocs() Called and Data populated", response);
         //console.log(response.body.$values);
      });
    }
  }


  GetQMTiers() {
    if(environment.production!=true)
      {
    this.http.get(environment.TiersRegistry,{
      headers: {
        'Authorization': "Bearer " + this.storage_token.get('access_token')
      },
      observe: 'response',
      responseType: 'json'
    }).subscribe((response: any) => 
      {
         this.httpResCheck.CheckHttpResponseNotify(response);
         this.qmtiers = response.body.$values;
      });
    }
  }

  async GetMeasuremenetPeriods(ContractStartDate: any,ContractEndDate: any):Promise<void> {

    //console.log("GetMeasuremenetPeriods() Making API Call",ContractStartDate,ContractEndDate);

    this.http.get(environment.MPRegistry,{
      headers: {
        'Authorization': "Bearer " + this.storage_token.get('access_token')
      },
      params: new HttpParams({fromObject: {
        ContractStartDate: ContractStartDate,
        ContractEndDate: ContractEndDate
      }}),
      observe: 'response',
      responseType: 'json'
    }).subscribe((response: any) => 
      {
         this.httpResCheck.CheckHttpResponseNotify(response);
         this.mps = response.body.$values;
         //console.log("GetMeasuremenetPeriods() Data loaded to memory", this.mps);

         return response.body.$values;
         //this.storage_token.set("measurementperiod", response.body.$values);
      });
  }

  GetIndicators() {
    if(environment.production!=true)
      {
    this.http.get(environment.IndicatorsRegistry,{
      headers: {
        'Authorization': "Bearer " + this.storage_token.get('access_token')
      },
      observe: 'response',
      responseType: 'json'
    }).subscribe((response: any) => 
      {
         this.httpResCheck.CheckHttpResponseNotify(response);
         this.indicators = response.body.$values;
         //console.log("GetIndicators() Called and Data populated", response);
         //console.log(response.body.$values);
      });
    }
  }


  GetReportTypes() {
    if(environment.production!=true)
      {
    this.http.get(environment.ReportTypesRegistry,{
      headers: {
        'Authorization': "Bearer " + this.storage_token.get('access_token')
      },
      observe: 'response',
      responseType: 'json'
    }).subscribe((response: any) => 
      {
         this.httpResCheck.CheckHttpResponseNotify(response);
         this.reporttypes = response.body.$values;
         //console.log("GetReportTypes() Called and Data populated", response);
         //console.log(response.body.$values);
      });
    }
  }


  


  userLoggedIn(access_token: string) {
    const payload = new HttpParams()
      .append('access_token', access_token) // access token

    this.http.post(environment.oauthUserInfoURL, payload, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        "Accept": "application/json"
      },
      observe: 'response',
      responseType: 'json'
    }).subscribe(response => {
      this.httpResCheck.CheckHttpResponseNotify(response);
      Object.entries(response.body || {}).forEach(([key, value], index) => {
        if (key == 'name') {
          this.setUsername(value);
        }
        if (key == 'email') {
          this.email = value;
        }
        if (key == 'sub') {
          this.uuid = value;
        }
      });
      //console.log("calling checkUserInternalExternal API");
      this.checkUserInternalExternal(this.email);

      //console.log("calling checkContractAdminUser API");
      this.checkContractAdminUser();
      //console.log("calling checkClientConfigUser API");
      this.checkClientConfigUser();
      // this.callDigitalSecuirtyAPI(Object.values(response)[0]);
      // //console.log(Object.values(response)[0]);
    },(error => {
      this.httpResCheck.NotifyError(error.status);
    }));
  }

  logout(): void {
    this.storage_token.clearAll();
    this.route.navigate(['/']);
  }

  refreshToken() {
    if (this.isTokenExpired) {
      this.isTokenExpired = false;
      this.refreshTokenValue = localStorage.getItem('data.refresh_token') || '';

      const payload = new HttpParams()
        .append('grant_type', environment.grantTypeRefreshToken) // access token
        .append('client_id', environment.oauthClientId)
        .append('refresh_token', this.refreshTokenValue.slice(1, -1))

      this.http.post(environment.oauthTokenUrl, payload, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        observe: 'response',
        responseType: 'json'
      }).subscribe(response => {
        this.httpResCheck.CheckHttpResponseNotify(response);
        ////console.log(response);
        Object.entries(response.body || {}).forEach(([key, value], index) => {
          if (key == 'access_token') {
            this.storage_token.set('access_token', value);
          }
        });
      },(error => {
        this.httpResCheck.NotifyError(error.status);
      }));
    }
  }

  setTimeOutForTokenExpiration(expirationTime: number) {
    setTimeout(() => {
      this.isTokenExpired = true;
    }, expirationTime);
  }

  getAccessToken(code: string, state: string) {
    if (state !== this.storage_token.get('state')) {
      alert('Invalid state');
      return;
    }

    const payload = new HttpParams()
      .append('grant_type', environment.grantTypeAuthorizationCode)
      .append('code', code)
      .append('code_verifier', this.storage_token.get('codeVerifier'))
      .append('redirect_uri', environment.oauthCallbackUrl)
      .append('client_id', environment.oauthClientId)
      .append('audience', 'http://localhost:5001');

    this.http.post(environment.oauthTokenUrl, payload, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      observe: 'response',
      responseType: 'json'
    }).subscribe(response => {
      this.httpResCheck.CheckHttpResponseNotify(response);
      Object.entries(response.body || {}).forEach(([key, value], index) => {
        ////console.log(response);
        if (key == 'access_token') {
          this.storage_token.set('access_token', value);
          this.loggedIn = true;
          this.userLoggedIn(value);
          this.route.navigate(['/home']);
          this.GetStates();
          this.GetEocs();
          this.GetProviders();
          this.GetIndicators();
          this.GetReportTypes();
          this.GetTINS();
          this.GetTINNames();
          this.GetQMTiers();
          
          AuthorizationInterceptor.accessToken = value;
        }
        if (key == 'refresh_token') {
          this.storage_token.set('refresh_token', value);
        }
        if (key == 'expires_in') {
          this.isTokenExpired = false;
          this.storage_token.set('expires_in', value);
          this.setTimeOutForTokenExpiration(1000 * value);
          this.setExpirationTime(value);
          this.storage_token.set('expires_in', value);
          this.userActive.next(true);
        }
      });
    },(error => {
      this.httpResCheck.NotifyError(error.status);
    }));
  }


  SaveContractDetails(TIN:any,STATE: any,MARKET:string,COUNTY:string,EOC_ID:string,CNTRCT_STAT:any,
    CNTRCT_BEGIN:any,CNTRCT_END:any,LOW_OUTLIER_VALUE:any,HIGH_OUTLIER_VALUE:any,BASELINE_COST_TARGET:any,
    GAIN_SHARING_PCT:any,SHARED_OPP_LIMIT_PCT:any,ALGORITHM_VERSION:any,PRECONTRACTING_DBNAME:any,OBH_IND:any,
    TIN_NAME: any,HISTORICAL_TIN_NAME:any, CONFIG_VERSION: any, ContractID:number, EditMode: boolean) 
  {

    const payload = new HttpParams()
    .append('access_token', this.storage_token.get('access_token')) // access token


    const body = { 
      "CONTRACT_ID": ContractID,
      "TIN": TIN,
      "STATE": STATE,
      "MARKET":MARKET,
      "COUNTY":COUNTY,
      "EOC_ID":EOC_ID,
      "CNTRCT_STAT": null,
      "CNTRCT_BEGIN":CNTRCT_BEGIN,
      "CNTRCT_END":CNTRCT_END,
       "LOW_OUTLIER_VALUE": LOW_OUTLIER_VALUE,
      "HIGH_OUTLIER_VALUE":HIGH_OUTLIER_VALUE,
      "BASELINE_COST_TARGET": BASELINE_COST_TARGET,
      "GAIN_SHARING_PCT": GAIN_SHARING_PCT,
      "SHARED_OPP_LIMIT_PCT": SHARED_OPP_LIMIT_PCT,
      "ALGORITHM_VERSION": ALGORITHM_VERSION,
      "PRECONTRACTING_DBNAME": PRECONTRACTING_DBNAME,
      "OBH_IND": OBH_IND,
      "TIN_NAME": TIN_NAME,
      "HISTORICAL_TIN_NAME": HISTORICAL_TIN_NAME,
      "CONFIG_VERSION": CONFIG_VERSION
    };

      const headers = { 'Content-Type': 'application/json',
                        'Authorization': "Bearer " + this.storage_token.get('access_token')
                      };

      // Calling POST method to save contract
      if(EditMode===false)
      {
        this.http.post<any>(environment.ContractBasicsRegistry, body, 
        { 
          headers 
        }).
        subscribe((response: any) => 
        {
          this.storage_token.set('CONTRACT_ID', response.contracT_ID);
          return response.contracT_ID;
        
      });
      }
      // Calling PUT method to update contract
      else
      {
        // console.log("Calling PUT API for contract update");
        // console.log(body);
        this.http.put<any>(environment.ContractBasicsRegistry, body, 
          { 
            headers 
          }).
          subscribe((response: any) => 
          {
            this.storage_token.set('CONTRACT_ID', response.contracT_ID);
            return response.contracT_ID;
          
        });
      }
  }

  SaveCostTargets(MEASUREMENT_PERIOD: any,LOW_COST_THRESHOLD:any,LOW_COST_THRESHOLD_ORIG:any, EditMode: boolean) 
  {
    const payload = new HttpParams()
    .append('access_token', this.storage_token.get('access_token')) // access token

    const body = { 
      "CONTRACT_ID": this.storage_token.get('CONTRACT_ID'),
      "MEASUREMENT_PERIOD": MEASUREMENT_PERIOD,
      "LOW_COST_THRESHOLD":LOW_COST_THRESHOLD,
      "LOW_COST_THRESHOLD_ORIG":LOW_COST_THRESHOLD_ORIG
    };
      const headers = { 'Content-Type': 'application/json',
                        'Authorization': "Bearer " + this.storage_token.get('access_token')};
    // Calling POST method to Save the contract
      if(EditMode===false)
      {

        this.http.post<any>(environment.CosttargetsRegistry, body, 
        { 
          headers 
        }).
        subscribe((response: any) => 
        {
          
      });
    }
     // Calling PUT method to update contract
    else{
      this.http.put<any>(environment.CosttargetsRegistry, body, 
        { 
          headers 
        }).
        subscribe((response: any) => 
        {
          
      });
    }
  }


  SaveQualityDetails(QUALITY_METRIC_NUMBER: any,MEASUREMENT_PERIOD:any,BASELINE_PERFORMANCE:any,
    QM_THRESHOLD:any, EOC_ID:any, QM_TIER_IND: any,QM_THRESHOLD_ORIG:any, QM_TIER_IND_ORIG:any , CNTRCT_BEGIN:any,
  CONFIG_VERSION:any, EditMode: boolean) 
      {
        const payload = new HttpParams()
        .append('access_token', this.storage_token.get('access_token')) // access token
    
        const body = { 
        "CONTRACT_ID": this.storage_token.get('CONTRACT_ID'),
        "QUALITY_METRIC_NUMBER": QUALITY_METRIC_NUMBER,
        "MEASUREMENT_PERIOD":MEASUREMENT_PERIOD,
        "BASELINE_PERFORMANCE":BASELINE_PERFORMANCE,
        "QM_THRESHOLD":QM_THRESHOLD,
        "EOC_ID":EOC_ID,
        "QM_TIER_IND":QM_TIER_IND,
        "QM_THRESHOLD_ORIG":QM_THRESHOLD_ORIG,
        "QM_TIER_IND_ORIG":QM_TIER_IND_ORIG,
        "CNTRCT_BEGIN": CNTRCT_BEGIN,
        "CONFIG_VERSION": CONFIG_VERSION
        };
          const headers = { 'Content-Type': 'application/json',
                            'Authorization': "Bearer " + this.storage_token.get('access_token')};
          // Calling POST method to save contract
          if(EditMode===false)
          {
            this.http.post<any>(environment.QualityDetailsRegistry, body, 
              { 
                headers 
              }).
              subscribe((response: any) => 
              {
                
               });        
          }
          // Calling PUT method to update contract
          else{
            this.http.put<any>(environment.QualityDetailsRegistry, body, 
              { 
                headers 
              }).
              subscribe((response: any) => 
              {
                
               });
          }
      }
     

      SaveClientConfig(RPT_DUE: any,RPT_CYCLE_ID:any) 
          {
            const payload = new HttpParams()
            .append('access_token', this.storage_token.get('access_token')) // access token
        
            const body = { 
            "RPT_DUE": RPT_DUE,
            "RPT_CYCLE_ID":RPT_CYCLE_ID
            };
              const headers = { 'Content-Type': 'application/json',
                                'Authorization': "Bearer " + this.storage_token.get('access_token')};
        
              this.http.post<any>(environment.ClientConfigRegistry, body, 
              { 
                headers 
              }).
              subscribe((response: any) => 
              {
                //console.log(response);
                this.storage_token.set('Client_Config_Result', response.result);
                this.storage_token.set('RPT_DUE', response.rpT_DUE);
                this.storage_token.set('RPT_CYCLE_ID', response.rpT_CYCLE_ID);
           });
          }



            GetClientConfigs() {
            const payload = new HttpParams()
            .append('access_token', this.storage_token.get('access_token')) // access token
        
            const body = { 
            "RPT_DUE": this.storage_token.get('RPT_DUE'),
            "RPT_CYCLE_ID": this.storage_token.get('RPT_CYCLE_ID')
            };
              const headers = { 'Content-Type': 'application/json',
                                'Authorization': "Bearer " + this.storage_token.get('access_token')};
        
              this.http.post<any>(environment.ClientConfigReportsRegistry, body, 
              { 
                headers 
              }).
              subscribe((response: any) => 
              {
                //Nullifying the memory when Report is Rendered. 
                this.storage_token.set('Client_Config_Result', false);
                this.storage_token.set('RPT_DUE', '');
                this.storage_token.set('RPT_CYCLE_ID', '');
                this.clientconfigs = response.$values;
           });
          }

          GetContractAdminList() {

            this.http.get(environment.ContractlistRegistry,{
        
              headers: {
        
                'Authorization': "Bearer " + this.storage_token.get('access_token')
        
              },
        
              observe: 'response',
        
              responseType: 'json'
        
            }).subscribe((response: any) =>
        
              {
        
                 this.httpResCheck.CheckHttpResponseNotify(response);
        
                 this.contractdatalist = response.body.$values;
        
        
              });
        
          }


          LoadContractData() 
          {
            const payload = new HttpParams()
            .append('access_token', this.storage_token.get('access_token')) // access token
        
            const body = this.storage_token.get('contract_id');
              
            
              const headers = { 'Content-Type': 'application/json',
                                'Authorization': "Bearer " + this.storage_token.get('access_token')};
        
              this.http.post<any>(environment.ContractDataRegistry, body, 
              { 
                headers 
              }).
              subscribe((response: any) => 
              {
                this.contractdata = response;
               });
         
          }
		  
		  
		  LoadContractQTData() 
          {
            const payload = new HttpParams()
            .append('access_token', this.storage_token.get('access_token')) // access token
        
            const body = this.storage_token.get('contract_id');

              const headers = { 'Content-Type': 'application/json',
                                'Authorization': "Bearer " + this.storage_token.get('access_token')};
        
              this.http.post<any>(environment.ContractDataQTRegistry, body, 
              { 
                headers 
              }).
              subscribe((response: any) => 
              {
                this.contractQTdata = response.$values;
           });
          }
		  
		   LoadContractCTData() 
          {
            const payload = new HttpParams()
            .append('access_token', this.storage_token.get('access_token')) // access token
        
             const body = this.storage_token.get('contract_id');
             
              const headers = { 'Content-Type': 'application/json',
                                'Authorization': "Bearer " + this.storage_token.get('access_token')};
        
              this.http.post<any>(environment.ContractDataCTRegistry, body, 
              { 
                headers 
              }).
              subscribe((response: any) => 
              {

                this.contractCTdata = response.$values;
           });
          }
        


          DeleteContractData(contract_id:any) 
          {
            const payload = new HttpParams()
            .append('access_token', this.storage_token.get('access_token')) // access token
        
            const body = contract_id;
              
            
              const headers = { 'Content-Type': 'application/json',
                                'Authorization': "Bearer " + this.storage_token.get('access_token')};
        
              this.http.post<any>(environment.ContractDeactivateRegistry, body, 
              { 
                headers 
              }).
              subscribe((response: any) => 
              {
                console.log(response);
               });
         
          }



      SearchReportCycles(RPT_CYCLE_ID:any, TIN:any) 
      {

          const payload = new HttpParams()
          .append('access_token', this.storage_token.get('access_token')) // access token


          const body = { 
            "RPT_CYCLE_ID": RPT_CYCLE_ID,
            "TIN": TIN
          };

            const headers = { 'Content-Type': 'application/json',
                              'Authorization': "Bearer " + this.storage_token.get('access_token')
                            };

            this.http.post<any>(environment.RecportcyclesRegistry, body, 
              { 
                headers 
              }).
              subscribe((response: any) => 
              {
                this.reportCyclesdata = response.$values;

                console.log("Loaded Data to Local var",response.$values);
              });
        }

        GetReportCycleData()
        {
          return this.reportCyclesdata;
        }

        
        

        }

